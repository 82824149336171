<template>
    <div v-if="component_done_loading" class="test">
        <test-topbar :test-obj="test" :options="{ forceHideTimer: true }"></test-topbar>
        <div class="col-xs-12 right-container">
            <div class="evaluationReport table-responsive evalutionReportDiv">
                <table v-if="test.settings.enableMichaelsenPointDistribution" class="table">
                    <thead>
                        <tr>
                            <th>MICHAELSEN EVALUATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="evalutionReportDiv border1pxGrey padding20 borderRadius3px hidden-xs hidden-sm">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="width280px">RECEIVER</th>
                                                <th class="width308px">GIVER</th>
                                                <th class="width280px">POINTS GIVEN</th>
                                                <th class="maxWidth142px" aria-label="Receiver Average">RECEIVER AVG.</th>
                                                <th class="maxWidth130px" aria-label="Team Average">TEAM AVG.</th>
                                                <th v-if="test.allowStudentsToViewPafScores">
                                                    PAF
                                                    <span class="badge badge-pill badge-info" style="display: inline"> BETA </span>
                                                </th>
                                                <th v-if="test.allowStudentsToViewSapaScores">
                                                    SAPA
                                                    <span class="badge badge-pill badge-info" style="display: inline"> BETA </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="border-right-d8d8d8" :rowspan="test.michaelsenPoints.length + 1">
                                                    <div class="flexOnly align-items">
                                                        <div class="profile_image marginRight5">
                                                            <div class="actual-image img24px"></div>
                                                        </div>
                                                        <div class="warningColor">Me</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <template v-for="(peer, peer_idx) in test.michaelsenPoints">
                                                <tr v-if="!peer.isMe">
                                                    <td v-if="test.enableAnonymity">
                                                        Anonymous
                                                        {{ peer_idx + 1 }}
                                                    </td>
                                                    <td v-else>
                                                        {{ peer.fullname }}
                                                    </td>
                                                    <td class="border-right-none">
                                                        {{ round(peer.point) }}
                                                    </td>
                                                    <td
                                                        v-if="peer_idx == 0"
                                                        :rowspan="test.michaelsenPoints.length + 1"
                                                        class="border-left-d8d8d8 textAlignCenter"
                                                    >
                                                        {{ round(peer.receiverAvg) }}
                                                    </td>
                                                    <td v-if="peer_idx == 0" :rowspan="test.michaelsenPoints.length + 1" class="textAlignCenter">
                                                        {{ round(peer.teamMean) }}
                                                    </td>
                                                    <td v-if="test.allowStudentsToViewPafScores && peer_idx == 0" :rowspan="test.michaelsenPoints.length + 1">
                                                        {{ peer.paf }}
                                                    </td>
                                                    <td v-if="test.allowStudentsToViewSapaScores && peer_idx == 0" :rowspan="test.michaelsenPoints.length + 1">
                                                        {{ peer.sapa }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>

                                <table class="hidden-md hidden-lg table evaluate-mobile">
                                    <thead>
                                        <tr>
                                            <th :colspan="2 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)">
                                                <div class="flexOnly align-items">
                                                    RECEIVER:
                                                    <div class="flexOnly align-items marginLeft10">
                                                        <div class="profile_image marginRight5">
                                                            <div class="actual-image img24px"></div>
                                                        </div>
                                                        <div class="warningColor">Me</div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>GIVER</th>
                                            <th>POINTS GIVEN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(peer, peer_idx) in test.michaelsenPoints">
                                            <tr v-if="!peer.isMe">
                                                <td v-if="test.enableAnonymity">
                                                    Anonymous
                                                    {{ peer_idx + 1 }}
                                                </td>
                                                <td v-else>
                                                    {{ peer.fullname }}
                                                </td>
                                                <td>
                                                    {{ round(peer.point) }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th :colspan="2 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="fontBold" aria-label="Receiver Average">RECEIVER AVG.:</td>
                                            <td :colspan="1 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)">
                                                {{ round(test.michaelsenPoints[0].receiverAvg) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fontBold" aria-label="Team Average">TEAM AVG.:</td>
                                            <td :colspan="1 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)">
                                                {{ round(test.michaelsenPoints[0].teamMean) }}
                                            </td>
                                        </tr>
                                        <tr v-if="test.allowStudentsToViewPafScores">
                                            <td class="fontBold" aria-label="PAF">
                                                PAF:<span class="badge badge-pill badge-info" style="display: inline"> BETA </span>
                                            </td>
                                            <td :colspan="1 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)">
                                                {{ round(test.michaelsenPoints[0].paf) }}
                                            </td>
                                        </tr>
                                        <tr v-if="test.allowStudentsToViewSapaScores">
                                            <td class="fontBold" aria-label="SAPA">
                                                SAPA:<span class="badge badge-pill badge-info" style="display: inline"> BETA </span>
                                            </td>
                                            <td :colspan="1 + (test.allowStudentsToViewPafScores ? 1 : 0) + (test.allowStudentsToViewSapaScores ? 1 : 0)">
                                                {{ round(test.michaelsenPoints[0].sapa) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table
                    v-if="(test.evaluationSettings.evaluateTeammates || test.evaluationSettings.evaluateThemselves) && test.peerQuestions.length != 0"
                    class="table marginTop30"
                >
                    <thead>
                        <tr>
                            <th>EVALUATION ON INDIVIDUAL ({{ test.peerQuestions[0].length }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="hidden-xs hidden-sm">
                            <td>
                                <template v-for="(question, question_idx) in test.peerQuestions[0]">
                                    <div class="evalutionReportDiv border1pxGrey padding20 borderRadius3px">
                                        <!-- <div
                                            class="flexOnly whiteSpacePreLine marginBottom20"
                                            v-html="
                                                question_idx +
                                                1 +
                                                '.&nbsp;' +
                                                question.question
                                            "
                                        ></div> -->

                                        <div class="flexOnly marginBottom20">
                                            {{ question_idx + 1 }}.&nbsp;<span class="question displayInlineBlock" v-html="question.question"></span>
                                        </div>

                                        <template v-if="question.type == 'rating'">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="width280px">RECEIVER</th>
                                                        <th class="width308px">GIVER</th>
                                                        <th class="width280px">POINTS GIVEN</th>
                                                        <th class="maxWidth142px" aria-label="Receiver Average">RECEIVER AVG.</th>
                                                        <th class="maxWidth130px" aria-label="Receiver Average">TEAM AVG.</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.peers.length != 0">
                                                    <tr>
                                                        <td class="border-right-d8d8d8" :rowspan="question.peers.length + 1">
                                                            <div class="flexOnly align-items">
                                                                <div class="profile_image marginRight5">
                                                                    <div class="actual-image img24px"></div>
                                                                </div>
                                                                <div class="warningColor">Me</div>
                                                            </div>
                                                            <!-- <div class="flexOnly align-items">
																	<div class="profile_image marginRight5">
													                    <template>
													                        <div class="actual-image img24px">
													                        </div>
													                    </template>
													                </div>
																	<div class="warningColor">
																		Team 1 Name (My Team)
																		<i class="fas fa-users marginLeft5"></i>
																	</div>
																</div> -->
                                                        </td>
                                                    </tr>

                                                    <template v-for="(peer, peer_idx) in question.peers">
                                                        <tr v-if="!peer.isMe || (peer.isMe && test.evaluationSettings.evaluateThemselves)">
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                                <!-- <template v-if="!peer.isMe">
																			Anonymous {{getAnonymousCount(question,peer_idx)}}
																		</template>
																		<template v-else>
																			<div class="flexOnly align-items">
																				<div class="profile_image marginRight5">
																                    <template>
																                        <div class="actual-image img24px">
																                        </div>
																                    </template>
																                </div>
																				<div class="warningColor">Sebastian Prescot (Me)</div>
																			</div>
																		</template> -->
                                                            </td>
                                                            <td class="border-right-none">
                                                                <div v-html="peer.response"></div>
                                                            </td>
                                                            <td
                                                                v-if="peer_idx == 0"
                                                                :rowspan="test.michaelsenPoints.length + 1"
                                                                class="border-left-d8d8d8 textAlignCenter"
                                                            >
                                                                {{ round(peer.receiverAvg) }}
                                                            </td>
                                                            <td v-if="peer_idx == 0" :rowspan="test.michaelsenPoints.length + 1" class="textAlignCenter">
                                                                {{ round(peer.teamMean) }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                        <template v-if="question.type == 'openended'">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="width280px">RECEIVER</th>
                                                        <th class="width308px">GIVER</th>
                                                        <th>EVALUATION GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.peers.length != 0">
                                                    <tr>
                                                        <td class="border-right-d8d8d8" :rowspan="question.peers.length + 1">
                                                            <div class="flexOnly align-items">
                                                                <div class="profile_image marginRight5">
                                                                    <div class="actual-image img24px"></div>
                                                                </div>
                                                                <div class="warningColor">Me</div>
                                                            </div>
                                                            <!-- <div class="flexOnly align-items">
																	<div class="profile_image marginRight5">
													                    <template>
													                        <div class="actual-image img24px">
													                        </div>
													                    </template>
													                </div>
																	<div class="warningColor">
																		Team 1 Name (My Team)
																		<i class="fas fa-users marginLeft5"></i>
																	</div>
																</div> -->
                                                        </td>
                                                    </tr>

                                                    <template v-for="(peer, peer_idx) in question.peers">
                                                        <tr v-if="!peer.isMe || (peer.isMe && test.evaluationSettings.evaluateThemselves)">
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                                <!-- <template v-if="!peer.isMe">
																			Anonymous {{getAnonymousCount(question,peer_idx)}}
																		</template>
																		<template v-else>
																			<div class="flexOnly align-items">
																				<div class="profile_image marginRight5">
																                    <template>
																                        <div class="actual-image img24px">
																                        </div>
																                    </template>
																                </div>
																				<div class="warningColor">Sebastian Prescot (Me)</div>
																			</div>
																		</template> -->
                                                            </td>
                                                            <td>
                                                                <span class="whiteSpacePreWrap" v-html="peer.response"></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                </template>
                            </td>
                        </tr>

                        <tr class="hidden-md hidden-lg">
                            <td class="padding0">
                                <template v-for="(question, question_idx) in test.peerQuestions[0]">
                                    <div class="evaluate-mobile-div">
                                        <!-- <div
                                            class="flexOnly whiteSpacePreLine marginBottom20"
                                            v-html="
                                                question_idx +
                                                1 +
                                                '.&nbsp;' +
                                                question.question
                                            "
                                        ></div> -->

                                        <div class="flexOnly marginBottom20">
                                            {{ question_idx + 1 }}.&nbsp;<span class="question displayInlineBlock" v-html="question.question"></span>
                                        </div>

                                        <template v-if="question.type == 'rating'">
                                            <table class="table evaluate-mobile">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <div class="flexOnly align-items">
                                                                RECEIVER:
                                                                <div class="flexOnly align-items marginLeft10">
                                                                    <div class="profile_image marginRight5">
                                                                        <div class="actual-image img24px"></div>
                                                                    </div>
                                                                    <div class="warningColor">Me</div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>GIVER</th>
                                                        <th>POINTS GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.peers.length != 0">
                                                    <template v-for="(peer, peer_idx) in question.peers">
                                                        <tr v-if="!peer.isMe || (peer.isMe && test.evaluationSettings.evaluateThemselves)">
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <div v-html="peer.response"></div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th colspan="2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="fontBold" aria-label="Receiver Average">RECEIVER AVG.:</td>
                                                        <td>
                                                            {{ round(question.peers[0].receiverAvg) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fontBold" aria-label="Team Average">TEAM AVG.:</td>
                                                        <td>
                                                            {{ round(question.peers[0].teamMean) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                        <template v-if="question.type == 'openended'">
                                            <table class="table evaluate-mobile">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <div class="flexOnly align-items">
                                                                RECEIVER:
                                                                <div class="flexOnly align-items marginLeft10">
                                                                    <div class="profile_image marginRight5">
                                                                        <div class="actual-image img24px"></div>
                                                                    </div>
                                                                    <div class="warningColor">Me</div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>GIVER</th>
                                                        <th>EVALUATION GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.peers.length != 0">
                                                    <template v-for="(peer, peer_idx) in question.peers">
                                                        <tr v-if="!peer.isMe || (peer.isMe && test.evaluationSettings.evaluateThemselves)">
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <span class="whiteSpacePreWrap" v-html="peer.response"></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table v-if="test.evaluationSettings.evaluateTeam" class="table marginTop30">
                    <thead>
                        <tr>
                            <th>EVALUATION ON TEAM ({{ test.teamQuestions[0].length }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="hidden-xs hidden-sm">
                            <td>
                                <template v-for="(question, question_idx) in test.teamQuestions[0]">
                                    <div class="evalutionReportDiv border1pxGrey padding20 borderRadius3px">
                                        <!-- <div
                                            class="flexOnly whiteSpacePreLine marginBottom20"
                                            v-html="
                                                question_idx +
                                                1 +
                                                '.&nbsp;' +
                                                question.question
                                            "
                                        ></div> -->

                                        <div class="flexOnly marginBottom20">
                                            {{ question_idx + 1 }}.&nbsp;<span class="question displayInlineBlock" v-html="question.question"></span>
                                        </div>

                                        <template v-if="question.type == 'rating'">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="width280px">RECEIVER</th>
                                                        <th class="width308px">GIVER</th>
                                                        <th class="width280px">POINTS GIVEN</th>
                                                        <th>TEAM AVG.</th>
                                                        <th>SECTION MEAN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.teams.length != 0">
                                                    <tr>
                                                        <td class="border-right-d8d8d8" :rowspan="question.teams.length + 1">
                                                            <div class="flexOnly align-items">
                                                                <div class="profile_image marginRight5">
                                                                    <div class="actual-image img24px"></div>
                                                                </div>
                                                                <div class="warningColor">MY TEAM</div>
                                                            </div>
                                                            <!-- <div class="flexOnly align-items">
																	<div class="profile_image marginRight5">
													                    <template>
													                        <div class="actual-image img24px">
													                        </div>
													                    </template>
													                </div>
																	<div class="warningColor">
																		Team 1 Name (My Team)
																		<i class="fas fa-users marginLeft5"></i>
																	</div>
																</div> -->
                                                        </td>
                                                    </tr>

                                                    <template v-for="(peer, peer_idx) in question.teams">
                                                        <tr>
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                                <!-- <template v-if="!peer.isMe">
																			Anonymous {{getAnonymousCount(question,peer_idx)}}
																		</template>
																		<template v-else>
																			<div class="flexOnly align-items">
																				<div class="profile_image marginRight5">
																                    <template>
																                        <div class="actual-image img24px">
																                        </div>
																                    </template>
																                </div>
																				<div class="warningColor">Sebastian Prescot (Me)</div>
																			</div>
																		</template> -->
                                                            </td>
                                                            <td>
                                                                <div v-html="peer.response"></div>
                                                            </td>
                                                            <td>
                                                                {{ round(peer.teamAvg) }}
                                                            </td>
                                                            <td>
                                                                {{ round(peer.sectionMean) }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                        <template v-if="question.type == 'openended'">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="width280px">RECEIVER</th>
                                                        <th class="width308px">GIVER</th>
                                                        <th>EVALUATION GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.teams.length != 0">
                                                    <tr>
                                                        <td class="border-right-d8d8d8" :rowspan="question.teams.length + 1">
                                                            <div class="flexOnly align-items">
                                                                <div class="profile_image marginRight5">
                                                                    <div class="actual-image img24px"></div>
                                                                </div>
                                                                <div class="warningColor">MY TEAM</div>
                                                            </div>
                                                            <!-- <div class="flexOnly align-items">
																	<div class="profile_image marginRight5">
													                    <template>
													                        <div class="actual-image img24px">
													                        </div>
													                    </template>
													                </div>
																	<div class="warningColor">
																		Team 1 Name (My Team)
																		<i class="fas fa-users marginLeft5"></i>
																	</div>
																</div> -->
                                                        </td>
                                                    </tr>

                                                    <template v-for="(peer, peer_idx) in question.teams">
                                                        <tr>
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                                <!-- <template v-if="!peer.isMe">
																			Anonymous {{getAnonymousCount(question,peer_idx)}}
																		</template>
																		<template v-else>
																			<div class="flexOnly align-items">
																				<div class="profile_image marginRight5">
																                    <template>
																                        <div class="actual-image img24px">
																                        </div>
																                    </template>
																                </div>
																				<div class="warningColor">Sebastian Prescot (Me)</div>
																			</div>
																		</template> -->
                                                            </td>
                                                            <td>
                                                                <span class="whiteSpacePreWrap" v-html="peer.response"></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                </template>
                            </td>
                        </tr>

                        <tr class="hidden-md hidden-lg">
                            <td class="padding0">
                                <template v-for="(question, question_idx) in test.teamQuestions[0]">
                                    <div class="evaluate-mobile-div">
                                        <!-- <div
                                            class="flexOnly whiteSpacePreLine marginBottom20"
                                            v-html="
                                                question_idx +
                                                1 +
                                                '.&nbsp;' +
                                                question.question
                                            "
                                        ></div> -->

                                        <div class="flexOnly marginBottom20">
                                            {{ question_idx + 1 }}.&nbsp;<span class="question displayInlineBlock" v-html="question.question"></span>
                                        </div>

                                        <template v-if="question.type == 'rating'">
                                            <table class="table evaluate-mobile">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <div class="flexOnly align-items">
                                                                RECEIVER:
                                                                <div class="flexOnly align-items marginLeft10">
                                                                    <div class="profile_image marginRight5">
                                                                        <div class="actual-image img24px"></div>
                                                                    </div>
                                                                    <div class="warningColor">MY TEAM</div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>GIVER</th>
                                                        <th>POINTS GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.teams.length != 0">
                                                    <template v-for="(peer, peer_idx) in question.teams">
                                                        <tr>
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <div v-html="peer.response"></div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th colspan="2"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="fontBold">TEAM AVG.:</td>
                                                        <td>
                                                            {{ round(question.teams[0].teamAvg) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fontBold">SECTION MEAN:</td>
                                                        <td>
                                                            {{ round(question.teams[0].sectionMean) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                        <template v-if="question.type == 'openended'">
                                            <table class="table evaluate-mobile">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">
                                                            <div class="flexOnly align-items">
                                                                RECEIVER:
                                                                <div class="flexOnly align-items marginLeft10">
                                                                    <div class="profile_image marginRight5">
                                                                        <div class="actual-image img24px"></div>
                                                                    </div>
                                                                    <div class="warningColor">MY TEAM</div>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>GIVER</th>
                                                        <th>EVALUATION GIVEN</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="question.teams.length != 0">
                                                    <template v-for="(peer, peer_idx) in question.teams">
                                                        <tr>
                                                            <td>
                                                                <template v-if="!peer.isMe">
                                                                    <template v-if="test.enableAnonymity">
                                                                        Anonymous
                                                                        {{ getAnonymousCount(question, peer_idx) }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ peer.fullname }}
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <div class="warningColor">Myself</div>
                                                                </template>
                                                            </td>
                                                            <td>
                                                                <span class="whiteSpacePreWrap" v-html="peer.response"></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </template>
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                id="evaluationQuestionInfoModal"
                class="modal"
                style="z-index: 50001 !important"
                tabindex="-1"
                role="dialog"
                aria-labelledby="evaluationQuestionInfoModal-title"
            >
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                                <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            </button>

                            <h2 id="evaluationQuestionInfoModal-title" class="modal-title">View Question</h2>
                        </div>
                        <div class="modal-body">
                            <question-display :question="previewQuestion"></question-display>
                            <div class="col-xs-12 buttonGroup marginTop30">
                                <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
				      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
				      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
					        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
					        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
					         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
					        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
					    </div>
					    <div style="margin-top:20px;" v-else>
					        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
					    </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        'test-topbar': require(`./../rat/partials/topbar.vue`).default,
        'question-display': require(`./../../questions/partials/question-display.vue`).default,
    },
    data() {
        return {
            component_done_loading: false,
            test: {},
            previewQuestion: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.test = response.data.data;
            that.processEchoListener();
            that.component_done_loading = true;
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },

    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                that.test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                that.test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                that.test.allowStudentsToViewPafScores = e.test.allowStudentsToViewPafScores;
                that.test.allowStudentsToViewSapaScores = e.test.allowStudentsToViewSapaScores;
                let oldAnonymity = that.test.enableAnonymity;
                that.test.enableAnonymity = e.test.enableAnonymity;
                if (!that.test.allowStudentsToViewScore) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has disabled viewing of test results.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
                if (oldAnonymity != that.test.enableAnonymity) {
                    that.fetchTest().then(function (response) {
                        that.test = response.data.data;
                    });
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestVisibilityUpdated',
                handler: h1,
            });
        },
        getAnonymousCount(question, peer_idx) {
            let count = 1;
            if (question.peers) {
                for (var i = 0; i < question.peers.length; i++) {
                    if (i < peer_idx && !question.peers[i].isMe) {
                        count++;
                    }
                }
            } else if (question.teams) {
                for (var i = 0; i < question.teams.length; i++) {
                    if (i < peer_idx && !question.teams[i].isMe) {
                        count++;
                    }
                }
            }

            return count;
        },
        getRatingDescription(question, peer) {
            let obj = _.find(question.ratingSettings, function (o) {
                return o.weight == peer.response;
            });
            //console.log(obj);
            return obj ? obj : {};
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#evaluationQuestionInfoModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/report');
        },
    },
};
</script>

<style scoped>
.border-left-d8d8d8 {
    border-left: 1px solid #d8d8d8;
}

.border-right-none {
    border-right: none;
}
.border-right-d8d8d8 {
    border-right: 1px solid #d8d8d8;
}

.width308px {
    width: 308px;
}

.width280px {
    width: 280px;
}

.evalutionReportDiv:not(:last-child) {
    margin-bottom: 20px;
}

.evaluationReport thead tr:last-child th {
    font-size: 16px;
}

.evaluate-mobile-div {
    border-bottom: 1px solid #d8d8d8;
    padding: 20px;
}

.evaluate-mobile-div:last-child {
    border: 0;
}

.evaluate-mobile tbody ~ tbody td {
    border-right: 0;
}
</style>
