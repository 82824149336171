<template>
    <div class="create row flex">
        <div class="col-xs-12 padding0">
            <div>
                <div v-if="mode == 'create'" class="marginBottom30">
                    <kr-select
                        class="no-error"
                        display="LMS TYPE <span class='important'>*</span>"
                        name="lms_type"
                        aria-label="lms type"
                        :form="form"
                        :items="[
                            { value: 'Canvas', description: 'Canvas' },
                            //{ value: 'Blackboard', description: 'Blackboard' },
                            { value: 'Moodle', description: 'Moodle' },
                            //{ value: 'Others', description: 'Others' },
                        ]"
                        item_value_idx="value"
                        item_desc_idx="description"
                        @changed="changedType"
                    >
                    </kr-select>
                </div>
                <template v-else>
                    <div class="form-group">
                        <label class="control-label">LMS TYPE</label>
                        <p class="form-control-static">
                            {{ form.model.lms_type }}
                        </p>
                    </div>
                </template>
                <div>
                    <kr-field
                        display="School Domain"
                        :form="form"
                        name="domain"
                        :options="{
                            placeholder: 'e.g https://myschool.instructure.com',
                        }"
                    ></kr-field>
                </div>
                <div>
                    <kr-field
                        display="Client ID"
                        :form="form"
                        name="client_id"
                        aria-label="client id"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="false">
                    <kr-field
                        display="Deployment ID"
                        :form="form"
                        name="deployment_id"
                        aria-label="DEPLOYMENT ID"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div>
                    <kr-field
                        display="Platform Name"
                        :form="form"
                        name="record_name"
                        aria-label="PLATFORM NAME"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="mode != 'create'">
                    <kr-field
                        display="Platform ID/Issuer ID/ISS"
                        :form="form"
                        name="issuer_id"
                        aria-label="PLATFORM ID/ISSUER ID/ISS"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="mode != 'create'">
                    <kr-field
                        display="Authentication URL"
                        :form="form"
                        name="authentication_url"
                        aria-label="AUTHENTICATION URL"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="mode != 'create'">
                    <kr-field
                        display="Authorization URL"
                        :form="form"
                        name="authorization_url"
                        aria-label="AUTHORIZATION URL"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="mode != 'create'">
                    <kr-field
                        display="Json Webkey URL"
                        :form="form"
                        name="json_webkey_url"
                        aria-label="JSON WEBKEY URL"
                        :options="{ placeholder: '', required: true }"
                    ></kr-field>
                </div>
                <div v-if="mode != 'create'">
                    <kr-select
                        class="no-error"
                        display="Signature Method <span class='important'>*</span>"
                        name="signature_method"
                        aria-label="SIGNATURE METHOD"
                        :form="form"
                        :items="[{ value: 'RS256', description: 'RS256' }]"
                        item_value_idx="value"
                        item_desc_idx="description"
                    ></kr-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'mode'],
    data() {
        return {};
    },
    created() {
        if (this.mode == 'create') {
            //this.changedType();
        }
    },
    methods: {
        changedType() {
            this.form.model.record_name = '';
            if (this.mode != 'create') {
                this.form.model.authentication_url = ``;
                this.form.model.authorization_url = ``;
                this.form.model.json_webkey_url = ``;
                this.form.model.issuer_id = '';
            }
        },
    },
};
</script>

<style scoped></style>
