<template>
    <div class="create row">
        <label class="control-label"> Default duration for Application Cases </label>
        <div class="flex">
            <div class="marginRight10">
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultApplicationSettings.durationDays"
                    :options="{
                        type: 'number',
                        min: 0,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Days</template>
                </kr-field>
            </div>
            <div class="marginRight10">
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultApplicationSettings.durationHours"
                    :options="{
                        type: 'number',
                        min: 0,
                        max: 23,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Hours</template>
                </kr-field>
            </div>
            <div>
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultApplicationSettings.durationMinutes"
                    :options="{
                        type: 'number',
                        min: 0,
                        max: 59,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Mins</template>
                </kr-field>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
