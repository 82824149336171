<template>
    <div class="col-md-12">
        <div class="col-middle">
            <div class="text-center text-center">
                <h1 class="error-number">500</h1>
                <h2>Internal Server Error</h2>
                <p>We track these errors automatically, but if the problem persists feel free to contact us. In the meantime, try refreshing.</p>
                <p>
                    <router-link :to="{ path: '/' }">Back to home.</router-link>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        $('body').addClass('login');
    },
};
</script>
