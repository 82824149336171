/*Kelvin Roger Ang Yap 2017
	krayap@gmail.com
*/
export default class Errors {
    constructor() {
        this.errors = {};
    }

    has(field) {
        return this.errors.hasOwnProperty(field);
    }
    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }
    clear(field) {
        if (field) {
            Vue.delete(this.errors, field);
            return;
        }
        this.errors = {};
    }
    //get errors
    record(errors) {
        if (_.isObject(errors)) {
            this.errors = errors;
        }
    }
    //append
    append(key, arrayErrors = []) {
        this.errors[key] = arrayErrors;
        var tempErrors = this.errors;
        this.errors = [];
        this.errors = tempErrors;
    }
    //get message
    recordMessage(message) {
        this.message = message;
    }
    //no submit until cleared.
    any() {
        if (!this.errors) return true;
        return Object.keys(this.errors).length > 0;
    }
    getMessage() {
        if (!this.message) return false;
        return this.message;
    }
}
