<template>
    <div>
        <div class="col-xs-12 col-md-9 paddingRight10 question">
            <kr-math :input="question.question" :safe="!question.questionIsHTML"></kr-math>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question'],
    watch: {},
};
</script>
