<template>
    <div class="create row flex">
        <div class="col-xs-12 col-md-6">
            <kr-select
                class="no-error"
                display="Onboarding"
                name="onboarding"
                :form="form"
                :items="[
                    { value: 'N/A', description: 'N/A' },
                    { value: 'To Onboard', description: 'To Onboard' },
                    { value: 'Scheduled', description: 'Scheduled' },
                    { value: 'Done', description: 'Done' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            >
            </kr-select>
            <div class="marginTop20">
                <kr-date display="ONBOARDING DATE" :form="form" name="date" :options="{ time: true }"></kr-date>
            </div>

            <div class="marginTop20">
                <kr-select class="no-error" display="Assigned Customer Advisor" name="customerAdvisorUuid" :items="superusers" :form="form">
                    <template #option="props">
                        <template v-for="user in props.model">
                            <option :value="user.uuid">
                                {{ user.displayName }}
                            </option>
                        </template>
                    </template>
                </kr-select>
            </div>
            <div class="marginTop20">
                <kr-field display="LMS" :form="form" name="lms" :options="{ placeholder: 'e.g., Brightspace' }"></kr-field>
                <kr-field display="School Year" :form="form" name="schoolYear" :options="{ placeholder: 'e.g., Trimester, Semester, etc' }"></kr-field>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['form', 'account', 'superusers'],
};
</script>
