<template>
    <div class="iframe-container" style="overflow: hidden; padding-top: 56.25%; position: relative">
        <iframe
            id="zoom-frame"
            allow="microphone; camera"
            style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%"
            :src="'https://success.zoom.us/wc/' + meetingNumber + '/join?prefer=1&un=' + username"
            frameborder="0"
            sandbox="allow-forms allow-scripts allow-same-origin"
        ></iframe>
    </div>
</template>

<script>
/*import { ZoomMtg } from '@zoomus/websdk';
console.log('checkSystemRequirements');
console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.2/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();*/
export default {
    props: ['options'],
    data() {
        return {
            apiKey: 'WfDBwUUGTv2TC8K678btkg',
            apiSecret: 'MgPddGxIVyVP9yuWN5DnX3oOAa31wGCL1T1q',
            iframeRedirects: 0,
            username: '',
            meetingNumber: '',
        };
    },
    created() {
        this.username = btoa(_.get(this.options, 'username', 'Kelvin'));
        this.meetingNumber = _.get(this.options, 'meetingNumber', '5652920283');
    },
    mounted() {
        var that = this;
        $('#zoom-frame').on('load', function () {
            if (that.iframeRedirects == 1) {
                that.$router.push({ name: 'auth.login' });
            } else {
                that.iframeRedirects++;
            }
        });
    },
    methods: {
        zoomStart(e) {
            //e.preventDefault();
            const meetConfig = {
                apiKey: this.apiKey,
                apiSecret: this.apiSecret,
                meetingNumber: this.meetingNumber,
                userName: 'Kr',
                passWord: '',
                leaveUrl: 'https://zoom.us',
                role: 1,
            };
            ZoomMtg.generateSignature({
                meetingNumber: meetConfig.meetingNumber,
                apiKey: meetConfig.apiKey,
                apiSecret: meetConfig.apiSecret,
                role: meetConfig.role,
                success(res) {
                    //console.log('signature', res.result);
                    ZoomMtg.init({
                        leaveUrl: 'http://' + location.host,
                        screenShare: true,
                        isSupportChat: true,
                        success() {
                            ZoomMtg.join({
                                meetingNumber: meetConfig.meetingNumber,
                                userName: meetConfig.userName,
                                signature: res.result,
                                apiKey: meetConfig.apiKey,
                                userEmail: 'email@gmail.com',
                                passWord: meetConfig.passWord,
                            });
                        },
                        error(res) {
                            //console.log(res);
                        },
                    });
                },
            });
        },
    },
};
</script>
