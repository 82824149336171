<template>
    <div class="create row flex">
        <div class="col-xs-12 col-md-6">
            <kr-field display="No of Faculty" :form="form" name="noOfFaculty" :options="{ placeholder: 'e.g., 20' }"></kr-field>
            <kr-field display="No of Student Licences" :form="form" name="noOfStudentLicences" :options="{ placeholder: 'e.g., John' }"></kr-field>
            <kr-field
                display="Activity Interests"
                :form="form"
                name="activityInterests"
                :options="{
                    placeholder: 'e.g., IRAT, TRAT, Applicaation Cases, 360 Degree',
                }"
            ></kr-field>
            <kr-select
                class="no-error"
                display="Usage Type"
                name="usageType"
                :form="form"
                :items="[
                    { value: 'Online-Only', description: 'Online-Only' },
                    { value: 'F2F-Only', description: 'F2F-Only' },
                    { value: 'Online and F2F', description: 'Online and F2F' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            ></kr-select>
            <div class="marginTop20">
                <kr-field display="Expansion Potential" :form="form" name="expansionPotential" :options="{ placeholder: 'e.g., Low $ 1000-10000' }"></kr-field>
            </div>
            <kr-field display="Course Start Date" :form="form" name="courseStartDate" :options="{ placeholder: '' }"></kr-field>
        </div>
    </div>
</template>

<script>
export default {
    props: ['form'],
};
</script>
