<template>
    <div class="create row flex">
        <div class="profileImg">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }"></kr-file-picture>
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="First Name"
                        :form="form"
                        name="firstname"
                        aria-label="first name"
                        :options="{
                            placeholder: 'Enter students first name',
                            required: true,
                        }"
                    ></kr-field>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="Last Name"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{
                            placeholder: 'Enter students last name',
                            required: true,
                        }"
                    ></kr-field>
                </div>
            </div>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <template v-if="mode == 'create'">
                        <kr-field
                            display="Email"
                            :form="form"
                            name="email"
                            aria-label="Email"
                            :options="{
                                placeholder: 'e.g. johndoe@example.com',
                                required: true,
                            }"
                        ></kr-field>
                    </template>
                    <template v-else>
                        <div class="form-group">
                            <label class="control-label">Email</label>
                            <p class="form-control-static">
                                {{ form.model.email ? form.model.email : '-' }}
                            </p>
                        </div>
                    </template>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="Student ID"
                        :form="form"
                        name="organisationID"
                        :options="{ placeholder: '' }"
                        :disabled="!form.model.email ? true : undefined"
                    ></kr-field>
                </div>
            </div>
            <div v-if="fromCourse" class="col-xs-12 flex">
                <div class="col-xs-12 col-md-12">
                    <kr-field :options="{ type: 'text-area' }" display="Remarks" :form="form" name="remarks"></kr-field>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm-6 col-sm-offset-3">
            <kr-select display="Account" :form="form" name="accountUuid" :items="accounts" item_value_idx="value" item_desc_idx="description" v-if="!isSetAccount" @changed="changedAccount">
                <template #option="props">
                    <template v-for="account in props.model">
                        <option :value="account.uuid" >
                            {{account.organisationName+'-'+account.accountName}}
                        </option>
                    </template>
                </template>
            </kr-select>
            <div class="form-group" v-else>
                <label class="control-label text-uppercase">ACCOUNT</label>
                <p class="form-control-static">{{account.organisationName+'-'+account.accountName}}</p>
            </div>
            <kr-toggle :form="form" name="roleId" display="Is Admin" :options="{trueval:2,falseval:3}"></kr-toggle>
            <template v-if="mode=='create'">
                <kr-field display="Email" :form="form" name="email" :options="{placeholder:'e.g. johndoe@example.com'}"></kr-field>
            </template>
            <template v-else>
                <div class="form-group">
                    <label class="control-label">Email</label>
                    <p class="form-control-static">{{form.model.email}}</p>
                </div>
            </template>

            <kr-field display="First name" :form="form" name="firstname" :options="{placeholder:'e.g. John'}"></kr-field>
            <kr-field display="Last name" :form="form" name="lastname" :options="{placeholder:'e.g. Doe'}"></kr-field>
            
            <template v-if="mode=='update'">
                <kr-change-password :form="form"></kr-change-password>
            </template>
        </div> -->
    </div>
</template>
<script>
export default {
    props: ['form', 'mode', 'fromCourse'],
    data() {
        return {
            course: {},
        };
    },
    created() {
        //this.changedAccount();
    },
    methods: {
        changedCourse() {
            let account_uuid = this.form.model.accountUuid; // The value of the selected option
            this.account = _.find(this.accounts, function (o) {
                return o.uuid == account_uuid;
            });
        },
    },
};
</script>
<style scoped></style>
