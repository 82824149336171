<template>
    <div>
        <div class="control-label">
            <span v-html="display"></span>
            <template v-if="tooltip">
                <i class="fa fa-question-circle" aria-hidden="true" data-toggle="tooltip" data-placement="top" :data-original-title="tooltip"></i>
            </template>
        </div>
        <template v-if="!reverse">
            <div v-if="!form.isHidden(name) && form.model[name].length != 0" class="flex align-items">
                <div class="col-xs-12 col-md-6 padding0">
                    <div>
                        <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                        <div v-for="(file, idx) in form.model[name]" class="flexSpaceBetween">
                            <template v-if="attachment_idx != idx">
                                <div>
                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                    <span class="marginRight5">
                                        <i class="fa fa-file-image-o"></i>
                                    </span>
                                    <span :href="file.url" download :aria-label="file.filename">{{ file.filename }}</span>
                                </div>
                                <div>
                                    <!-- <span>{{(file.size/1000000).toFixed(2)}} MB</span> -->
                                    <span aria-label="View" @click.prevent="viewPreview(file.url)"> <i class="fas fa-eye" aria-hidden="true"></i> </span>&nbsp;
                                    <span class="important" aria-label="Delete" @click.prevent="attachment_idx = idx">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="marginBottom20">
                                    <div>
                                        <div class="inlineFlex">
                                            <div>{{ idx + 1 }}.&nbsp;</div>
                                            <div>&nbsp;<i class="fa fa-file-image-o" aria-hidden="true"></i>&nbsp;</div>
                                            <div>
                                                <div class="marginBottom5" :href="file.url" download>
                                                    {{ file.filename }}
                                                </div>
                                                <div class="marginBottom5">Are you sure you want to delete this attachment?</div>
                                                <div class="inlineFlex">
                                                    <button
                                                        id="modal-logout-btn"
                                                        class="btn btn-danger subBtn"
                                                        aria-label="Yes, Delete"
                                                        @click.prevent="
                                                            remove(idx);
                                                            attachment_idx = -1;
                                                        "
                                                    >
                                                        Yes, Delete
                                                    </button>
                                                    <button
                                                        id="modal-dismiss-btn"
                                                        class="btn btn-default subBtn"
                                                        aria-label="No, Cancel"
                                                        @click.prevent="attachment_idx = -1"
                                                    >
                                                        No, Cancel
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="marginLeft10" aria-label="View" @click.prevent="viewPreview(file.url)">
                                                <i class="fas fa-eye" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!form.isHidden(name)" :class="{ 'has-error': form.errors.has(name) }">
                <!-- <div class="">
		    	<div class="input-group">
		        	<span class="input-group-btn">
			        	<button class="btn btn-primary subBtn" @click.prevent="openFileStack">
			        		<i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i> 
			        		<template v-else><span class="fa fa-download"></span> Upload Attachments</template>
			        	</button>
			      	</span>
		        </div>
		        <span class="help-block" v-show="form.errors.has(name)">
		            <strong>{{ form.errors.get(name) }}</strong>
		        </span>
		    </div> -->
                <button class="btn btn-primary subBtn" aria-label="Upload Attachments" @click.prevent="openFileStack">
                    <i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw" aria-hidden="true"></i>
                    <template v-else>
                        <span class="fa fa-download" aria-hidden="true"></span>
                        Upload Attachments
                    </template>
                </button>
                <div v-if="uploadMsg" class="marginTop10" v-html="uploadMsg"></div>
                <span v-show="form.errors.has(name)" class="help-block">
                    <strong>{{ form.errors.get(name) }}</strong>
                </span>
            </div>
        </template>
        <template v-else>
            <div v-if="!form.isHidden(name)" :class="{ 'has-error': form.errors.has(name) }">
                <!-- <div class="">
		    	<div class="input-group">
		        	<span class="input-group-btn">
			        	<button class="btn btn-primary subBtn" @click.prevent="openFileStack">
			        		<i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i> 
			        		<template v-else><span class="fa fa-download"></span> Upload Attachments</template>
			        	</button>
			      	</span>
		        </div>
		        <span class="help-block" v-show="form.errors.has(name)">
		            <strong>{{ form.errors.get(name) }}</strong>
		        </span>
		    </div> -->
                <button class="btn btn-primary subBtn" aria-label="Upload Attachments" @click.prevent="openFileStack">
                    <i v-if="is_uploading" class="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
                    <template v-else> <span class="fa fa-download"></span> Upload Attachments </template>
                </button>
                <div v-if="uploadMsg" class="marginTop10" v-html="uploadMsg"></div>
                <span v-show="form.errors.has(name)" class="help-block">
                    <strong>{{ form.errors.get(name) }}</strong>
                </span>
            </div>
            <div v-if="!form.isHidden(name) && form.model[name].length != 0" class="flex align-items marginTop10">
                <div class="col-xs-12 col-md-6 padding0">
                    <div>
                        <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                        <div v-for="(file, idx) in form.model[name]" class="flexSpaceBetween">
                            <template v-if="attachment_idx != idx">
                                <div>
                                    <span>{{ idx + 1 }}.&nbsp;</span>
                                    <span class="marginRight5">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </span>
                                    <span :href="file.url" download :aria-label="file.filename">{{ file.filename }}</span>
                                </div>
                                <div>
                                    <!-- <span>{{(file.size/1000000).toFixed(2)}} MB</span> -->
                                    <span aria-label="View" @click.prevent="viewPreview(file.url)"> <i class="fas fa-eye" aria-hidden="true"></i> </span>&nbsp;
                                    <span class="important" aria-label="Delete" @click.prevent="attachment_idx = idx">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </template>
                            <template v-else>
                                <div class="marginBottom20">
                                    <div>
                                        <div class="inlineFlex">
                                            <div>{{ idx + 1 }}.&nbsp;</div>
                                            <div>&nbsp;<i class="fa fa-file-image-o"></i>&nbsp;</div>
                                            <div>
                                                <div class="marginBottom5" :href="file.url" download>
                                                    {{ file.filename }}
                                                </div>
                                                <div class="marginBottom5">Are you sure you want to delete this attachment?</div>
                                                <div class="inlineFlex">
                                                    <button
                                                        id="modal-logout-btn"
                                                        class="btn btn-danger subBtn"
                                                        aria-label="Yes, Delete"
                                                        @click.prevent="
                                                            remove(idx);
                                                            attachment_idx = -1;
                                                        "
                                                    >
                                                        Yes, Delete
                                                    </button>
                                                    <button
                                                        id="modal-dismiss-btn"
                                                        class="btn btn-default subBtn"
                                                        aria-label="No, Cancel"
                                                        @click.prevent="attachment_idx = -1"
                                                    >
                                                        No, Cancel
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="marginLeft10" aria-label="View" @click.prevent="viewPreview(file.url)">
                                                <i class="fas fa-eye" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import * as filestack from 'filestack-js';
export default {
    props: ['display', 'form', 'name', 'options', 'uploadMsg'],
    data() {
        //image:form.model[this.name]
        return {
            client: null,
            is_uploading: false,
            preview_url: '',
            tooltip: false,
            attachment_idx: -1,
            reverse: false,
        };
    },
    created() {
        this.reverse = _.get(this.options, 'reverse', false);
    },
    mounted() {
        $(document).on('focusin', function (e) {
            if ($(e.target).closest('.fsp-picker').length) {
                e.stopImmediatePropagation();
            }
        });
    },
    methods: {
        openFileStack() {
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            this.client
                .picker({
                    maxFiles: 20,
                    uploadInBackground: false,
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                        '.rmd',
                    ],
                    onUploadStarted: function (file) {
                        that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        that.is_uploading = false;
                        let currentLength = that.form.model[that.name].length;
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            _.set(that.form.model, that.name + '.' + currentLength++, res.filesUploaded[i]);
                        }
                        that.$emit('changed');
                    },
                })
                .open();
        },
        remove(idx) {
            this.form.model[this.name].splice(idx, 1);
        },
        viewPreview(url) {
            window.open(url, '_blank');
            this.$emit('preview-attachment', url);
        },
    },
};
</script>
<style scoped>
.uploadedFile {
    margin-bottom: 15px;
}

.uploadedFile:last-child {
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .qns_Attachment button {
        margin-left: 20px;
    }
}

@media (max-width: 991px) {
    .qns_Attachment button {
        width: 100%;
        margin-top: 10px;
    }

    .qns_Attachment label {
        width: 100%;
    }
}

.marginBottom0 {
    margin-bottom: 0;
}
</style>
