import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
window.Pusher.logToConsole = false;
if (!['production'].includes(process.env.VUE_APP_ENV)) {
    window.Pusher.logToConsole = true;
}
export default class KrPusher {
    connect(credential = {}) {
        let domain = new URL(credential.API_URL);
        domain = domain.hostname;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_KEY,
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            forceTLS: true,
            authEndpoint: `https://${domain}/broadcasting/auth`,
            auth: {
                headers: {
                    authorization: `Bearer ${credential.access_token}`,
                    accept: `application/json`,
                },
            },
        });
    }
}
