<template>
    <div>
        <!-- Modal -->
        <div :id="'resetPasswordModal' + id" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="resetPasswordModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="resetPasswordModal-title" class="modal-title">Reset Password</h2>
                    </div>
                    <div class="modal-body">
                        <div class="textAlignCenter">
                            <p>Here are your options for resetting the following password.</p>

                            <p class="marginTop15 marginBottom15">
                                <b v-if="passwordReset.user != null">{{ passwordReset.user.displayName }}</b>
                                <br />
                                <b v-if="passwordReset.user != null">{{ passwordReset.user.email ? '(' + passwordReset.user.email + ')' : '' }}</b>
                            </p>
                        </div>
                        <div v-if="passwordReset.status != 'processing'" class="row">
                            <div v-if="passwordReset.status == 'success'" class="alert alert-success" role="alert" style="margin-bottom: 10px">
                                <i class="fa fa-check marginRight8" aria-hidden="true" />You have successfully performed password reset!
                            </div>
                            <div v-if="passwordReset.status == 'fail'" class="alert alert-danger" role="alert" style="margin-bottom: 10px">
                                <i class="fa fa-cross marginRight8" aria-hidden="true" />Error in your password reset, please try again!
                            </div>
                            <div class="col-xs-12" style="padding-bottom: 15px">
                                <button
                                    v-if="passwordReset.user.email"
                                    id="modal-reset-pwd-token-btn"
                                    class="btn btn-success mainBtn"
                                    style="width: 100%"
                                    aria-label="Send Password Reset
                  Instructions via Email"
                                    @click.prevent="resetPassword('token')"
                                >
                                    <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />Send Password Reset Instructions via Email
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button
                                    id="modal-reset-temporary-pwd-btn"
                                    class="btn btn-default mainBtn"
                                    style="width: 100%"
                                    :aria-label="'Set Temporary Password to ' + passwordReset.pin"
                                    @click.prevent="resetPassword('pin')"
                                >
                                    Set Temporary Password to
                                    {{ passwordReset.pin }}
                                </button>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-xs-12" style="text-align: center">Resetting..</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['options'],
    data() {
        return {
            id: '',
            passwordReset: {
                user: {},
                status: 'none',
                pin: '',
                type: '',
                organisation: '',
            },
        };
    },
    created() {
        this.id = _.get(this.options, 'id', '');
    },
    mounted() {
        var that = this;
        Events.listen('open-reset-password-modal' + this.id, function (data) {
            that.openResetPasswordModal(data);
        });
    },
    methods: {
        //email/pin type
        resetPassword(type = 'token') {
            this.passwordReset.status = 'processing';
            this.passwordReset.type = type;
            var that = this;
            if (type == 'token') {
                axios
                    .post('password/send-token', {
                        email: this.passwordReset.user.email,
                    })
                    .then(function (response) {
                        that.passwordReset.status = 'success';
                    })
                    .catch(function (errors) {
                        that.passwordReset.status = 'fail';
                    });
            }
            if (type == 'pin') {
                axios
                    .post('users/' + this.passwordReset.user.uuid + '/send-temporary-password', { temporaryPassword: this.passwordReset.pin })
                    .then(function (response) {
                        that.passwordReset.status = 'success';
                    })
                    .catch(function (errors) {
                        that.passwordReset.status = 'fail';
                    });
            }
        },
        openResetPasswordModal(data) {
            this.passwordReset.user = data.user;
            this.passwordReset.status = 'none';
            /*if(this.passwordReset.user.email==null){
            	 this.passwordReset.pin = this.passwordReset.user.identity;
            	//generic
            }else{
            	this.passwordReset.pin = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
            }*/
            this.passwordReset.pin = Math.floor(100000 + Math.random() * 900000).toString();
            $('#resetPasswordModal' + this.id).modal('show');
        },
    },
};
</script>
