<template>
    <div>
        <template
            v-if="
                isTimerExtended &&
                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) ||
                    (testObj.type == 'application' && testObj.others.applicationType != 'team')) &&
                testObj.settings.hasTimeLimit &&
                isNotEnded &&
                display != 0
            "
        >
            <div
                v-tooltip="{
                    content: 'Has extra ' + extendedTimeLabel,
                }"
                class="attendanceTag-purple inlineFlex align-items"
            >
                <i class="fa fa-clock marginRight8" aria-hidden="true" />
                <p>
                    <template v-if="display === 1">
                        {{ extendedTimeLabel }}
                    </template>
                    <template v-else-if="display === 2">
                        {{ time }}
                    </template>
                    <template v-else-if="display === 3"> Paused </template>
                    <template v-else-if="display === 4"> Ended </template>
                </p>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: ['testObj', 'student'],
    data() {
        return {
            display: -1, //1 = label, 2 = timer
            timer: null,
            time: '',
            extendedTimeLabel: '',
            isNotEnded: true,
        };
    },
    computed: {
        isTimerExtended() {
            if (!this.student) {
                return false;
            } else {
                if (
                    this.student.extraTimeValues ||
                    (this.student.expectedEndingTime && this.testObj.status == 'not started' && this.testObj.expectedEndingTime)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
    watch: {
        testObj: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
        student: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
        /*time(val) {
            if (val == 'Ended') {
                setTimeout(() => {
                    return (this.isNotEnded = false);
                }, 1000);
            }
        },*/
    },
    beforeUnmount() {
        this.stopTimer();
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.processExtendedTimeLabel();
            if (!this.isTimerExtended) {
                this.stopTimer();
                return;
            }
            if (_.get(this.testObj, 'settings.type') == 'synchronous') {
                if (['not started', 'paused'].includes(this.testObj.status)) {
                    if (this.testObj.status == 'not started' && this.student.status == 'started' && this.testObj.expectedEndingTime) {
                        this.display = 2;
                        this.initTimer();
                    } else if (this.testObj.status == 'not started' && this.student.status == null && this.student.expectedEndingTime) {
                        this.display = 0;
                    } else {
                        this.display = 1;
                    }
                } else {
                    if (this.testObj.status == 'paused') {
                        this.display = 3;
                        this.stopTimer();
                    } else {
                        this.display = 2;
                        this.initTimer();
                    }
                }
            } else if (_.get(this.testObj, 'settings.type') == 'asynchronous') {
                if (['not started', 'scheduled'].includes(this.testObj.status)) {
                    this.display = 1;
                } else {
                    if (['completed', 'ended'].includes(this.testObj.status)) {
                        this.display = 1;
                        this.stopTimer();
                    } else {
                        if (this.student.status == null && !this.student.expectedEndingTime && this.extendedTimeLabel != '') {
                            //student has not started the test
                            this.display = 1;
                        } else {
                            this.display = 2;
                            this.initTimer();
                        }
                    }
                }
            }
        },
        isExtendedTimerEnded() {
            if (this.isTimerExtended) {
                var endTime = this.convertToReadableDate(_.get(this.student, 'expectedEndingTime')).dateObj;
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                return !(distance > 0);
            } else {
                return false;
            }
        },
        processExtendedTimeLabel() {
            let value = this.student.extraTimeValues;
            if (value === null) {
                return '';
            }
            var parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
            const days = parsedValue.durationDays || 0;
            const hours = parsedValue.durationHours || 0;
            const minutes = parsedValue.durationMinutes || 0;
            const seconds = parsedValue.durationSeconds || 0;
            const timeParts = [];
            if (days > 1) {
                timeParts.push(days + ' days ');
            } else if (days == 1) {
                timeParts.push(days + ' day ');
            }

            if (hours > 1) {
                timeParts.push(hours + ' hrs ');
            } else if (hours == 1) {
                timeParts.push(hours + ' hr ');
            }

            if (minutes > 1) {
                timeParts.push(minutes + ' mins ');
            } else if (minutes == 1) {
                timeParts.push(minutes + ' min ');
            }

            if (seconds > 1) {
                timeParts.push(seconds + ' secs ');
            } else if (minutes == 1) {
                timeParts.push(seconds + ' sec ');
            }

            this.extendedTimeLabel = timeParts.length >= 1 ? timeParts.join(' ') : '';
        },
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        initTimer() {
            this.stopTimer();
            var that = this;
            var b = this.student.expectedEndingTime;
            const endTime = this.convertToReadableDate(b).dateObj;
            this.timer = setInterval(() => {
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    const days = distance.days();
                    if (days == 0) {
                        const hours = ('0' + distance.hours()).slice(-2);
                        const minutes = ('0' + distance.minutes()).slice(-2);
                        const seconds = ('0' + distance.seconds()).slice(-2);
                        that.time = `${hours} : ${minutes} : ${seconds}`;
                    } else {
                        that.time = `${days}  day(s)`;
                    }
                } else {
                    that.time = '00:00:00';
                }
            }, 1000);
        },
    },
};
</script>
