<template>
    <div>
        <canvas id="boxplot"></canvas>
        <div id="boxplot-legend" class="chart-legend"></div>
    </div>
</template>
<script>
export default {
    props: {
        chartdata: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            chart: {},
        };
    },
    watch: {
        chartdata: {
            handler: function () {
                this.renderChart(_.cloneDeep(this.chartdata), this.options);
            },
            deep: true,
        },
    },
    mounted() {
        this.renderChart(_.cloneDeep(this.chartdata), this.options);
    },
    methods: {
        renderChart(data, options) {
            const ctx = document.getElementById('boxplot').getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'boxplot',
                data: data,
                options: options,
            });
            if (options.legendCallback) {
                var s = this.chart.generateLegend().replace(/\"1-legend"/g, 'legend');
                document.getElementById('boxplot-legend').innerHTML = s;
            }
        },
    },
};
</script>
<style scoped>
.chart-legend {
    margin-top: 10px;
    padding-left: 17px;
}
</style>
