<template>
    <div v-if="!form.isHidden(name)" :id="name + '-div'" :class="[cls, form.errors.has(name) ? 'has-error' : '']" :style="style">
        <slot name="label">
            <template v-if="hasLabel">
                <label class="control-label" v-html="display"></label>
            </template>
        </slot>
        <div>
            <template v-if="!form.isReadonly(name)">
                <div :class="{ 'input-group': hasAddOnSlot }">
                    <div class="form-group-select-wrapper form-dropdown">
                        <select
                            :id="id"
                            v-model="value"
                            :name="nameLabel"
                            :disabled="form.isDisabled(name) ? true : undefined"
                            class="form-control"
                            :aria-label="ariaLabel || display || name"
                            @change="change"
                        >
                            <slot name="option" :model="iteration">
                                <template v-for="item in iteration">
                                    <option :value="item[item_value_idx]" :disabled="item['_disabled'] ? true : undefined">
                                        {{ item[item_desc_idx] }}
                                    </option>
                                </template>
                            </slot>
                        </select>
                    </div>
                    <div v-if="hasAddOnSlot" class="input-group-addon">
                        <slot name="addon"> </slot>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-for="item in iteration">
                    <template v-if="item[item_value_idx] == form.model[name]">
                        <p class="form-control-static">
                            {{ item[item_desc_idx] }}
                        </p>
                    </template>
                </template>
            </template>
            <span v-show="form.errors.has(name)" class="help-block">
                <strong>{{ form.errors.get(name) }}</strong>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: ['display', 'url', 'form', 'items', 'name', 'item_value_idx', 'item_desc_idx', 'options', 'ariaLabel'],
    data: function () {
        return {
            render: true,
            iteration: [],
            value: '',
            hasLabel: true,
            cls: 'form-group',
            style: null,
            id: '',
            nameLabel: '',
        };
    },
    computed: {
        hasAddOnSlot() {
            return !!this.$slots['addon'];
        },
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                this.value = _.get(this.form.model, this.name);
            },
        },
        items: {
            deep: true,
            handler() {
                this.iteration = this.items;
            },
        },
    },
    created() {
        this.id = _.get(this, 'options.id', this.name);
        this.nameLabel = _.get(this, 'options.name', this.name);
        //placeholder
        var label = _.get(this, 'options.hasLabel', true);
        this.hasLabel = label;
        //placeholder
        var cls = _.get(this, 'options.cls', null);
        if (cls != null) {
            this.cls = cls;
        }
        //placeholder
        var style = _.get(this, 'options.style', null);
        if (style != null) {
            this.style = style;
        }

        if (!this.url) {
            this.iteration = this.items;
            this.value = _.get(this.form.model, this.name);
        } else {
            this.getOptions();
        }
    },
    methods: {
        getOptions() {
            if (this.url) {
                if (_.isEmpty(this.iteration)) {
                    var success = function (response) {
                        this.iteration = response.data.data;
                        this.value = this.form.model[this.name];
                    }.bind(this);
                    axios.get(this.url).then(success);
                }
            }
            return true;
        },
        change(event) {
            _.set(this.form.model, this.name, this.value);
            this.form.errors.clear(event.target.name);
            this.$emit('changed');
        },
    },
};
</script>
