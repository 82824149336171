<template>
    <!--PUT HTML HERE-->
    <div class="col-xs-12 right-container">
        <div class="stepsNaviFixed nav-tabsContainer">
            <div class="links">
                <ul>
                    <li :class="{ active: is_teacher }" @click="is_teacher = true">
                        <a
                            id="teacher-tab"
                            class="nav-link"
                            :class="{ active: is_teacher }"
                            data-toggle="tab"
                            href="#teacher"
                            role="tab"
                            aria-controls="teacher"
                            aria-selected="true"
                        >
                            TEACHERS
                        </a>
                    </li>
                    <li :class="{ active: !is_teacher }" @click="is_teacher = false">
                        <a
                            id="student-tab"
                            class="nav-link"
                            :class="{ active: !is_teacher }"
                            data-toggle="tab"
                            href="#student"
                            role="tab"
                            aria-controls="student"
                            aria-selected="true"
                        >
                            STUDENTS
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tab-content">
            <div id="teacher" class="tab-pane fade" :class="{ 'show active in': is_teacher }" role="tabpanel" aria-labelledby="teacher-tab">
                <div class="pageTitle">
                    <h1>Teachers</h1>
                    <p class="col-md-6">
                        This segment incorporates all the teachers who have been enrolled in your university’s account. There are varying levels of access and
                        these depend on the teacher’s function on the account.
                        <a href="https://help.intedashboard.com/how-do-i-reset-an-instructors-password" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            Reset an Instructor's Password
                        </a>
                    </p>
                </div>

                <teachers-listing />
            </div>
            <div id="student" class="tab-pane fade" :class="{ 'show active in': !is_teacher }" role="tabpanel" aria-labelledby="student-tab">
                <div class="pageTitle">
                    <h1>Students</h1>
                    <p class="col-md-6">
                        This page contains all the students enrolled in your university’s account. Students who appear here can be added to courses and divided
                        into groups.
                        <a href="https://help.intedashboard.com/my-learner-cannot-see-the-activity" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            My student cannot see the activity
                        </a>
                    </p>
                </div>

                <students-listing />
            </div>
        </div>
        <kr-reset-password />
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
export default {
    components: {
        'students-listing': require(`./students/partials/index.vue`).default,
        'teachers-listing': require(`./teachers/partials/index.vue`).default,
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            is_teacher: this.$route.name != 'students.index',
        };
    },
    watch: {
        is_teacher: {
            handler: function () {
                var tabName = this.is_teacher ? 'Teachers' : 'Students';
                document.title = `${tabName} | People | InteDashboard | TBL Makes Teams Work`;
            },
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fa fa-users"></i>&nbsp;People',
            breadcrumb: [{ name: 'Home' }, { name: 'People', link: 'people.index' }],
        });

        document.title = `Teachers | People | InteDashboard | TBL Makes Teams Work`;
    },
};
</script>
