<template>
    <div class="create row">
        <div class="col-xs-12">
            <kr-select display="NEW COURSE OWNER" :form="form" name="userUuid" :items="course.teachers" item_value_idx="value" item_desc_idx="description">
                <template #option="props">
                    <template v-for="teacher in props.model">
                        <option v-if="teacher.accountType != 'Owner'" :value="teacher.uuid">
                            {{ teacher.displayName }}
                        </option>
                    </template>
                </template>
            </kr-select>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'course'],
};
</script>
