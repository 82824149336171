import { useAuthUserStore } from '@/stores/auth';
export default class KrAuth {
    constructor(options) {}
    init() {
        if (!this.store) {
            this.store = useAuthUserStore();
            if (this.isLoggedIn()) {
                this.auth = this.store.auth;
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.auth.access_token;
                //console.log('originL'+window.axioss.headers.common['Authorization']);
                //console.log(window.axioss.headers.common['Authorization']);
            }
        }
    }
    login(data, options = {}) {
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token;
        this.init();
        var auth = {
            access_token: data.data.token,
            serverTimeOnLogin: data.data.currentTime,
            SOCKET_URL: data.data.socket_url,
            API_URL: data.data.api_url,
            passwordExpired: data.data.passwordExpired,
            isUsingTemporaryPassword: data.data.isUsingTemporaryPassword,
            password_modal_shown: false,
            remember: data.remember === 'true' ? true : false,
        };
        var profile = data.data.user;
        //profile.isPaid = data.data.isPaid;
        if (profile.account.version == null) {
            profile.account.version = 'full';
        }
        var push = {
            auth: auth,
            profile: profile,
            options: options,
        };
        if (options.impersonate) {
            this.store.impersonate_login(push);
        } else {
            this.store.login(push);
        }
    }
    logout() {
        this.init();
        if (this.isImpersonation()) {
            this.store.impersonate_logout();
        } else {
            delete axios.defaults.headers.common['Authorization'];
            this.store.logout();
        }
    }
    isLoggedIn() {
        this.init();
        return this.store.isLoggedIn;
    }
    user(original = false) {
        this.init();
        if (!original) {
            return this.store.profile;
        } else {
            return this.store.originalProfile;
        }
    }
    isImpersonation() {
        this.init();
        return this.store.isImpersonation;
    }
    client() {
        this.init();
        return this.store.client;
    }
    credential() {
        this.init();
        return this.store.auth;
    }
    options() {
        this.init();
        return this.store.options;
    }
    socket() {
        this.init();
        return this.store.socket;
    }
    getAuthHeader() {
        this.init();
        if (this.isLoggedIn) {
            return;
        }
        if (this.token != '') {
            return 'Bearer ' + this.token;
        } else {
            return false;
        }
    }
}
