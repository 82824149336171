<template>
    <div class="create row">
        <label class="control-label"> Default duration for TRATs </label>
        <div class="flex">
            <div class="marginRight10">
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultTratSettings.durationDays"
                    :options="{
                        type: 'number',
                        min: 0,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Days</template>
                </kr-field>
            </div>
            <div class="marginRight10">
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultTratSettings.durationHours"
                    :options="{
                        type: 'number',
                        min: 0,
                        max: 23,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Hours</template>
                </kr-field>
            </div>
            <div>
                <kr-field
                    class="no-error"
                    :form="form"
                    name="settings.defaultTratSettings.durationMinutes"
                    :options="{
                        type: 'number',
                        min: 0,
                        max: 59,
                        hasLabel: false,
                        dateWidth: true,
                    }"
                    @changed="$emit('debouncedUpdate')"
                    @keyup="$emit('debouncedUpdate')"
                >
                    <template #addon>Mins</template>
                </kr-field>
            </div>
        </div>

        <div class="iratPoints table-responsive marginTop15">
            <table class="table">
                <thead>
                    <tr>
                        <th colspan="2">TRAT DEFAULT POINT SETTINGS</th>
                    </tr>
                    <tr>
                        <th>Attempt</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(score, idx) in form.model.settings.defaultTratSettings.points" :key="idx">
                        <td>Attempt {{ idx + 1 }}</td>
                        <td>
                            <!-- <input
                type="number"
                class="form-control"
                v-model="form.model.settings.defaultTratSettings[idx]"
                style="width:auto"
                @keyup="$emit('debouncedUpdate')"
                @blur="$emit('instantUpdate')"
              /> -->
                            <kr-field
                                :form="form"
                                :name="'settings.defaultTratSettings.points.' + idx"
                                :options="{
                                    type: 'number',
                                    hasLabel: false,
                                    min: 0,
                                    showError: false,
                                }"
                                @changed="$emit('debouncedUpdate')"
                                @keyup="$emit('debouncedUpdate')"
                                @blur="$emit('instantUpdate')"
                            >
                            </kr-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
<style scoped></style>
