<template>
    <div ref="viewer"></div>
</template>
<script>
/* eslint-disable */
import WebViewer from '@pdftron/pdfjs-express-viewer';
export default {
    name: 'WebViewer',
    props: {
        fileUrl: String,
        canDownload: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        init() {
            let instance = WebViewer.getInstance(this.$refs.viewer);
            if (instance) {
                instance.closeDocument();
                if (!this.canDownload) {
                    instance.UI.disableElements(['downloadButton']);
                    instance.UI.disableElements(['printButton']);
                } else {
                    instance.UI.enableElements(['downloadButton']);
                    instance.UI.enableElements(['printButton']);
                }
                instance.UI.loadDocument(this.fileUrl);
            } else {
                WebViewer(
                    {
                        path: `/plugins/pdfviewer/public/`,
                        initialDoc: this.fileUrl,
                        licenseKey: process.env.VUE_APP_PDFJS_KEY,
                    },
                    this.$refs.viewer
                ).then((instance) => {
                    //hide download and print
                    if (!this.canDownload) {
                        instance.UI.disableElements(['downloadButton']);
                        instance.UI.disableElements(['printButton']);
                    } else {
                        instance.UI.enableElements(['downloadButton']);
                        instance.UI.enableElements(['printButton']);
                    }
                    const { Core, UI } = instance;
                    UI.setHeaderItems((header) => {
                        header.push({
                            type: 'actionButton',
                            img: 'https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg',
                            onClick: () => {
                                const currentPage = Core.documentViewer.getCurrentPage();
                                const totalPages = Core.documentViewer.getPageCount();
                                const atLastPage = currentPage === totalPages;
                                if (atLastPage) {
                                    Core.documentViewer.setCurrentPage(1);
                                } else {
                                    Core.documentViewer.setCurrentPage(currentPage + 1);
                                }
                            },
                        });
                    });
                });
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        fileUrl: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
div {
    width: 100%;
    height: 100vh;
}
</style>
