<template>
    <zoom v-if="component_done_loading" :options="{ username: auth.user().displayName }"></zoom>
</template>

<script>
import KrAuth from '../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
        };
    },
    mounted() {
        this.component_done_loading = true;
    },
};
</script>
