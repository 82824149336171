<template>
    <div v-shortkey="{ test: ['ctrl', 's'], test: ['meta', 's'] }" @shortkey="submitConfirmAccessibility">
        <div :class="{ flexSpaceBetween: notMobile && reverse }">
            <div v-show="!isPreview" :class="{ 'float-right': !reverse }">
                <button
                    v-if="!isTestCompleted"
                    v-tooltip="{
                        content: isSubmitTestButtonDisabled ? 'You have incomplete questions' : '',
                    }"
                    class="test-submission btn btn-success"
                    :class="{ disabled: isSubmitTestButtonDisabled }"
                    :aria-label="!isSubmitTestButtonDisabled ? 'Submit Test' : 'Submit Test Button Disabled'"
                    @click.prevent="submitTest"
                >
                    <i class="fas fa-check marginRight8" aria-hidden="true"></i>Submit Test
                </button>
            </div>

            <div v-if="showPagination" :class="{ 'float-left': !reverse }">
                <question-pagination :class="{ marginTop20: !notMobile }" :test-obj="testObj"></question-pagination>
            </div>

            <div class="clear"></div>
        </div>
    </div>
</template>
<script>
import { testMixins } from './../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['options'],
    components: {
        'question-pagination': require(`./question-pagination.vue`).default,
    },
    data() {
        return {
            reverse: false,
            showPagination: true,
        };
    },
    computed: {
        isSubmitTestButtonDisabled() {
            return (
                this.total_questions != this.answered_count ||
                (this.testObj.type == 'trat' && !this.isTeamLeader) ||
                (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team' && !this.isTeamLeader)
            );
        },
        notMobile() {
            if (window.innerWidth >= 992) {
                return true;
            }

            return false;
        },
    },
    created() {
        this.reverse = _.get(this.options, 'reverse', false);
        this.showPagination = _.get(this.options, 'showPagination', this.showPagination);
    },
    methods: {
        submitConfirmAccessibility(event) {
            var that = this;
            event.preventDefault();
            var test_submit_modal_buttons = $('#test_confirm_modal .buttonGroup button');
            switch (event.srcKey) {
                case 'test':
                    if (!that.isSubmitTestButtonDisabled && !that.isTestCompleted) {
                        that.submitTest();
                    }
                    break;
            }
        },
        submitTest() {
            Events.fire('submit-test');
        },
    },
};
</script>

<style>
.float-right {
    float: right;
}
.float-left {
    float: left;
}

.clear {
    clear: both;
}
</style>
