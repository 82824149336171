<template>
    <div class="create row flex">
        <div class="panelTitle">
            <h3>2-Factor Authentication (2FA)</h3>
            <p>
                2FA is used for email/password sign in only. When enabled, users will receive a 6-digit pin via email following email/password verification to
                complete their sign in.
            </p>
        </div>
        <div>
            <kr-toggle
                :options="{ reverse: true, onNoff: true }"
                name="settings.mfaForTeachers"
                display="2FA for instructors"
                :form="form"
                @changed="$emit('instantUpdate')"
            />
            <!-- <kr-toggle
          name="settings.mfaForTeachers"
          display="Enable 2FA For Teachers"
          :form="form"
          class="marginBottom15 marginTop15 flexSpaceBetween"
          @changed="$emit('instantUpdate')"
        >
        </kr-toggle> -->
            <!-- <kr-toggle
          name="settings.mfaForStudents"
          display="Enable 2FA For Students"
          :form="form"
          class="marginBottom15 marginTop15 flexSpaceBetween"
          @changed="$emit('instantUpdate')"
        >
        </kr-toggle> -->
            <!--<kr-toggle name="settings.enableLti" display="Enable LTI" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween">
        </kr-toggle>-->
            <kr-toggle
                :options="{ reverse: true, onNoff: true }"
                name="settings.mfaForStudents"
                display="2FA for Learners"
                :form="form"
                class="marginTop15"
                @changed="$emit('instantUpdate')"
            />
            <!--<kr-toggle name="settings.enableLti" display="Enable LTI" :form="form" class="marginBottom15 marginTop15 flexSpaceBetween">
			</kr-toggle>-->
            <!--<kr-field display="Max failed sign-in attempts" name="settings.maxFailedSignInAttempts" :form="form" :options="{type:'number',min:0,showError:false}"></kr-field>-->
        </div>
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
<style scoped></style>
