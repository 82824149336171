<template>
    <div>
        <div v-if="component_done_loading" class="timer-wrapper">
            <section class="timer-section">
                <div class="timer-container">
                    <div class="col-xs-12">
                        <topbar-timer :test-obj="test" :options="{ showWindowLink: false }"></topbar-timer>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            test: {},
            component_done_loading: false,
            debouncedUpdate: null,
        };
    },
    created() {
        $('body').addClass('login');
        var that = this;
        this.debouncedUpdate = _.debounce(
            function (data) {
                this.fetchTest().then(function (response) {
                    that.test = response.data.data;
                    if (!that.component_done_loading) {
                        that.component_done_loading = true;
                        that.processEchoListeners();
                    }
                });
            },
            500,
            { maxWait: 800 }
        );
        Events.fire('topbar_update', {
            title: '<i class="fas fa-pencil-ruler"></i>&nbsp;Activity',
        });
        this.debouncedUpdate();
    },
    methods: {
        processEchoListeners() {
            var that = this;

            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.debouncedUpdate();
            };
            let c1 = window.Echo.private(`course.${this.test.course.uuid}.teacher`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });
        },
        fetchTest() {
            return axios.get('/tests/' + this.$route.params.id + '/dashboard/overview');
        },
    },
    components: {
        'topbar-timer': require(`./partials/topbar-timer.vue`).default,
    },
};
</script>
<style scoped>
.timer-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
</style>
