<template>
    <div class="container">
        <router-view v-slot="{ Component }">
            <transition :is="Component" name="slide-fade" mode="out-in"> </transition>
        </router-view>
    </div>
</template>
<script>
export default {};
</script>
