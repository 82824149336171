<template>
    <div style="padding: 20px">
        <section id="payment-section" class="payment-container big">
            <div class="payment-logo-container">
                <a href="https://www.intedashboard.com">
                    <div class="payment-logo" style="background-image: url(/img/whiteLogo.png)"></div>
                </a>
            </div>
            <div class="payment-body-wrapper">
                <div v-if="step == 0">
                    <div class="fontBold fontSize24 marginBottom20 textAlignCenter">THANK YOU!</div>
                    <div>Dear Ms Lydia Dean,</div>
                    <div class="marginTop20">
                        Your order <span class="fontBold blue">#1324543</span>, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea jakjga commodo
                        consequat. Duis aute irure dolor in reprehenderit in de voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                        occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit animest laborum.
                    </div>
                    <div class="marginTop20">
                        Any Questions? Suggestions? Insightful showe thoughts?
                        <span class="fontBold blue">Shoot us an email.</span>
                    </div>
                    <div class="flex marginTop20">
                        <div class="col-xs-12 col-md-6">
                            <div class="colBorder heading">Order Number</div>
                            <div>#1324543</div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-xs hidden-sm">
                            <div class="colBorder heading">Order Date</div>
                            <div>7 May 2018</div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-md hidden-lg marginTop20">
                            <div class="colBorder heading">Order Date</div>
                            <div>7 May 2018</div>
                        </div>
                    </div>
                    <div class="flex marginTop20">
                        <div class="col-xs-12 col-md-6">
                            <div class="colBorder heading">Start Date</div>
                            <div>7 May 2017</div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-xs hidden-sm">
                            <div class="colBorder heading">End Date</div>
                            <div>7 May 2018</div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-md hidden-lg marginTop20">
                            <div class="colBorder heading">End Date</div>
                            <div>7 May 2018</div>
                        </div>
                    </div>
                    <!-- <div class='flex marginTop20'>
                        <div class="col-xs-12 col-md-6">
                            <div class="colBorder heading">Shipping Address</div>
                            <div class="col-xs-7">
                                <div>CognaLearn Pte Ltd</div>
                                <div>75 Ayer Rajah Crescent, #01-01,</div>
                                <div>Singapore 139951</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-xs hidden-sm">
                            <div class="colBorder heading">Billing Address</div>
                            <div class="col-xs-7">
                                <div>CognaLearn Pte Ltd</div>
                                <div>75 Ayer Rajah Crescent, #01-01,</div>
                                <div>Singapore 139951</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 hidden-md hidden-lg marginTop20">
                            <div class="colBorder heading">Billing Address</div>
                            <div class="col-xs-7">
                                <div>CognaLearn Pte Ltd</div>
                                <div>75 Ayer Rajah Crescent, #01-01,</div>
                                <div>Singapore 139951</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="fontBold marginTop30">Here’s What You Ordered:</div>
                    <div class="flex marginTop20">
                        <div class="col-xs-6 colBorder heading">Item</div>
                        <div class="col-xs-6 colBorder heading flex">
                            <div class="col-xs-6">Qty</div>
                            <div class="col-xs-6">Price</div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="col-xs-6 colBorder heading">
                            <div class="col-xs-8 col-md-12">Intedashboard Premium Account</div>
                        </div>
                        <div class="col-xs-6 colBorder subHeading flex">
                            <div class="col-xs-6">1</div>
                            <div class="col-xs-6">SGD 10,000</div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="col-xs-6 colBorder heading">
                            <div class="col-xs-8 col-md-12">Intedashboard Standard Account</div>
                        </div>
                        <div class="col-xs-6 colBorder subHeading flex">
                            <div class="col-xs-6">1</div>
                            <div class="col-xs-6">SGD 5,000</div>
                        </div>
                    </div>
                    <div class="textAlignRight">Subtotal: SGD 15,000</div>
                    <div class="colBorder subHeading textAlignRight">Tax: SGD 100</div>
                    <div class="fontBold textAlignRight">TOTAL: SGD 15,100</div>
                    <!-- <span style="font-size:20px;font-weight:bold;display:block;">Activate your InteDashboard Account</span>

                    <p style="margin:0;margin-top:20px;">Welcome to InteDashboard!</p>
                    <p style="margin:0;margin-top:15px;line-height:2"><span style="font-weight:bold;color:#23356d;">Inte</span><span style="font-weight:bold;color:#0077de;">Dashboard</span> is a new online platform created by a team from<br/>
                    Duke-NUS Medical School exclusively for team-based learning (“TBL”) educators.</p>
                    <p style="margin:0;margin-top:15px;"><span style="font-weight:bold;color:#23356d;">Inte</span><span style="font-weight:bold;color:#0077de;">Dashboard</span> automates TBL administration and provides real-time data analytics.</p>

                     <div style="margin-top:30px;">
                        <button class="btn btn-info btn-action hidden-xs hidden-sm" style="margin:0;max-width:430px;width:430px;" @click="moveStep(1)">Activate My Account</button>
                        <button class="btn btn-info btn-action hidden-md hidden-lg" style="margin:0;width:100%;" @click="moveStep(1)">Activate My Account</button>
                    </div> -->
                </div>
            </div>
            <div v-if="step == 0" class="payment-pre-footer">
                <div class="row" style="border-bottom: 2px solid #ebebeb">
                    <div class="payment-pre-top">
                        <div class="pre-column">
                            <div>
                                Help Center<br />
                                <a @click.prevent="">help@intedashboard.com</a>
                            </div>
                        </div>
                        <div class="dot">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>
                        <div class="pre-column">
                            <div>
                                Support 24/7<br />
                                <a @click.prevent="">+65 6904 8226</a>
                            </div>
                        </div>
                        <div class="dot">
                            <i class="fa fa-circle" aria-hidden="true"></i>
                        </div>
                        <div class="pre-column">
                            <div>
                                Notifications<br />
                                <a @click.prevent="">Unsubscribe</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="socialMedia">
                    <p>View Privacy Policy</p>
                    <div class="smIcons">
                        <span>
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </span>
                        <span>
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </span>
                        <span>
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                        </span>
                        <span>
                            <i class="fa fa-google-plus" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="step == 0" class="payment-footer">Copyright © CognaLearn Pte Ltd 2019</div>
        </section>
    </div>
</template>
<script>
import KrForm from '../../components/forms/form';
import KrAuth from '../../components/auth/auth';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            step: 0,
            initial_form_data: {},
            form: new KrForm({
                fields: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    studentID: '',
                    amount: '',
                    label: '',
                    payment_mode: 'default!:access',
                    access_code: '',
                    discount_code: '',
                    card_number: '',
                    exp_month: '',
                    exp_year: '',
                },
                props: {},
                urls: {
                    post: '',
                },
            }),
        };
    },
    created() {
        $('body').addClass('login');
    },
    created() {
        let data = _.get(this.$route.query, 'data', null);
        if (data != null) {
            data = JSON.parse(data);
            this.form.model.firstname = data.firstname;
            this.form.model.lastname = data.lastname;
            this.form.model.email = data.email;
            this.form.model.studentID = data.studentID;
            this.initial_form_data = data;
            this.form.model.label = this.initial_form_data.paymentOptions[0].label;
            this.form.model.amount = this.initial_form_data.paymentOptions[0].amount;
            for (var i = 0; i < this.initial_form_data.paymentOptions.length; i++) {
                let obj = this.initial_form_data.paymentOptions[i];
                this.initial_form_data.paymentOptions[i].label += ' (' + obj.amount + '$)';
            }
        } else {
            this.$router.push({ name: 'login' });
        }
    },
    methods: {
        validate() {
            if (!this.form.model.firstname) {
                this.form.errors.append('firstname', ['Firstname is a required field']);
            }
            if (!this.form.model.lastname) {
                this.form.errors.append('lastname', ['Lastname is a required field']);
            }
            if (!this.form.model.email) {
                this.form.errors.append('email', ['Email is a required field']);
            }
            if (!this.form.model.amount) {
                this.form.errors.append('amount', ['Amount is a required field']);
            }
            if (this.form.model.payment_mode == 'access') {
                if (!this.form.model.access_code) {
                    this.form.errors.append('access_code', ['Access code is a required field']);
                }
            }
        },
        moveStep(step) {
            if (step != 0) {
                $('#payment-section').removeClass('big');
            }
            this.step = step;
        },
        changeMode(mode) {
            if (mode == 1) {
                $('#access_button').addClass('selected');
                $('#paypal_button').removeClass('selected');
                this.form.model.payment_mode = 'access';
                //student mode
            } else {
                $('#access_button').removeClass('selected');
                $('#paypal_button').addClass('selected');
                this.form.model.payment_mode = 'paypal';
            }
        },
        submit() {
            var that = this;
            this.validate();
            if (this.form.errors.any()) {
                swal('Error', 'Please recheck form for errors', 'error');
                return false;
            }
            axios
                .post('/access-codes/' + this.form.model.access_code + '/' + this.form.model.studentID, this.form.model)
                .then(function (response) {
                    that.$router.push({
                        name: 'auth.password.reset',
                        params: { mode: 'students', otp: response.data.otp },
                    });
                })
                .catch(function (errors) {
                    if (that.form.model.payment_mode == 'access') {
                        that.form.errors.append('access_code', [errors.response.data.reasons]);
                    }
                });
            //
        },
    },
};
</script>
<style>
/*.payment-body-wrapper{
    width:100%;
    padding:30px 20px 10px;
}*/
</style>
<style scoped>
.colBorder.heading {
    padding-bottom: 5px;
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 10px;
    font-weight: bold;
}

.colBorder.subHeading {
    padding-bottom: 5px;
    border-bottom: 2px solid #d8d8d8;
    margin-bottom: 10px;
}
.payment-container.big {
    max-width: 670px;
}
.payment-container {
    max-width: 430px;
    background: #fff;
    border: solid 1px #dfdfdf;
    margin: 100px auto;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(88, 88, 88, 0.5);
}
.payment-logo-container {
    background-color: #495060;
    color: white;
    height: 95px;
    padding: 15px;
    text-align: center;
}
.payment-logo {
    width: 270px;
    height: 68px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
}
.payment-pre-footer {
    width: 100%;
    background: #f8f8f8;
    padding: 30px 20px;
    text-align: center;
}

.pre-column div {
    font-weight: bold;
    font-size: 12px;
}

.pre-column a {
    font-weight: normal;
    color: #0078d7;
}

.socialMedia {
    padding-top: 20px;
}

.payment-footer {
    background-color: #495060;
    height: 36px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.smIcons span {
    margin-left: 50px;
}

.smIcons span:first-child {
    margin-left: 0;
}

.emailForm,
.accCodeForm,
.discountCode,
.cardNo,
.expiredDate,
.othersDetails {
    margin-top: 30px;
}

.choosePayType,
.choosePayMethod {
    margin-top: 30px;
    text-align: left;
}

.choosePayType h5,
.choosePayMethod h5,
.totalPayment h5 {
    margin: 0;
    margin-bottom: 10px;
}

.login-type-button.selected,
.login-type-button:hover {
    border: solid 2px #47a647;
    color: #47a647;
}

.btnAccess i,
.btnPaypal i {
    margin-right: 5px;
}

.login-type-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-type-button span {
    height: 24px;
}

.paypalLogo {
    width: 80px;
}

.fa-check-circle {
    width: 14px;
}

.totalPayment {
    text-align: left;
}

.totalPayment span {
    font-size: 24px;
}

.accCodeHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
}

.accCodeHeader i {
    margin-right: 5px;
}

.accCodeClear {
    color: #b40020;
    font-weight: bold;
}

button {
    margin: 0;
}

@media (max-width: 991px) {
    .payment-pre-top {
        padding-bottom: 20px;
        text-align: center;
    }
    .pre-column {
        width: 100%;
        margin: 5px 0;
    }
    .pre-column:last-child {
        margin-bottom: 0;
    }
    .lastName,
    .expYr,
    .totalPayment,
    .btnPaypal {
        margin-top: 30px !important;
    }
}

@media (min-width: 992px) {
    .payment-pre-top {
        padding-bottom: 20px;
        display: flex;
        text-align: center;
    }
    .pre-column {
        width: 33.33%;
    }

    .payment-pre-top .dot {
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nameForm,
    .choosePayMethodChoices,
    .expiredDate,
    .othersDetails {
        display: flex;
    }

    .firstName,
    .expMonth,
    .cvvNo,
    .btnAccess {
        width: 50%;
        padding-right: 10px;
    }

    .lastName,
    .expYr,
    .totalPayment,
    .btnPaypal {
        width: 50%;
        padding-left: 10px;
    }
}
</style>
