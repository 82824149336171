<template>
    <div class="create row flex">
        <div class="col-xs-12 col-md-12">
            <kr-select
                display="TYPE <span class='important'>*</span>"
                name="type"
                aria-label="type"
                :form="form"
                :items="[
                    { value: 'Basic', description: 'Basic' },
                    { value: 'Onboarding', description: 'Onboarding Session' },
                    { value: 'Customer', description: 'Customer Call' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            ></kr-select>
        </div>
        <template v-if="form.model.type == 'Onboarding'">
            <div class="col-xs-12 col-md-12 marginTop20">
                <kr-select
                    class="no-error"
                    display="ONBOARDING"
                    name="status"
                    :form="form"
                    :items="[
                        { value: 'N/A', description: 'N/A' },
                        { value: 'To Onboard', description: 'To Onboard' },
                        { value: 'Scheduled', description: 'Scheduled' },
                        { value: 'Done', description: 'Done' },
                    ]"
                    item_value_idx="value"
                    item_desc_idx="description"
                >
                </kr-select>
                <div class="marginTop20">
                    <kr-date display="ONBOARDING DATE" :form="form" name="date" :options="{ time: true }"></kr-date>
                </div>
                <div class="marginTop20">
                    <kr-select
                        class="no-error"
                        display="ASSIGNED CUSTOMER ADVISOR <span class='important'>*</span>"
                        name="customerAdvisorUuid"
                        aria-label="ASSIGNED CUSTOMER ADVISOR"
                        :items="superusers"
                        :form="form"
                    >
                        <template #option="props">
                            <template v-for="user in props.model">
                                <option :value="user.uuid">
                                    {{ user.displayName }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div class="marginTop20">
                    <kr-field
                        display="SCHOOL YEAR"
                        :form="form"
                        name="schoolYear"
                        :options="{
                            placeholder: 'e.g., Trimester, Semester, etc',
                            hideHelpBlockIfNoErrors: true,
                        }"
                    ></kr-field>
                </div>
            </div>
        </template>
        <template v-if="form.model.type == 'Customer'">
            <div class="col-xs-12 col-md-12 marginTop20">
                <kr-select
                    class="no-error"
                    display="CALL STATUS <span class='important'>*</span>"
                    name="status"
                    aria-label="CALL STATUS"
                    :form="form"
                    :items="[
                        { value: 'N/A', description: 'N/A' },
                        { value: 'Scheduled', description: 'Scheduled' },
                        { value: 'Done', description: 'Done' },
                    ]"
                    item_value_idx="value"
                    item_desc_idx="description"
                >
                </kr-select>
                <div class="marginTop20">
                    <kr-date display="CALL DATE" :form="form" name="date" :options="{ time: true }"></kr-date>
                </div>
                <div class="marginTop20">
                    <kr-select
                        class="no-error"
                        display="ASSIGNED CUSTOMER ADVISOR <span class='important'>*</span>"
                        name="customerAdvisorUuid"
                        aria-label="ASSIGNED CUSTOMER ADVISOR"
                        :items="superusers"
                        :form="form"
                    >
                        <template #option="props">
                            <template v-for="user in props.model">
                                <option :value="user.uuid">
                                    {{ user.displayName }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div class="marginTop20">
                    <kr-select
                        class="no-error"
                        display="TYPE <span class='important'>*</span>"
                        name="callType"
                        aria-label="TYPE"
                        :form="form"
                        :items="[
                            { value: 'Checkin', description: 'Checkin' },
                            {
                                value: 'Feature Requests',
                                description: 'Feature Requests',
                            },
                            {
                                value: 'Additional Onboarding',
                                description: 'Additional Onboarding',
                            },
                            {
                                value: 'Security/Compliance',
                                description: 'Security/Compliance',
                            },
                            {
                                value: 'Accessibility',
                                description: 'Accessibility',
                            },
                            {
                                value: 'Product Issues',
                                description: 'Product Issues',
                            },
                            {
                                value: 'Admin Issues',
                                description: 'Admin Issues',
                            },
                            {
                                value: 'Billing Issues',
                                description: 'Billing Issues',
                            },
                            {
                                value: 'Major Escalation',
                                description: 'Major Escalation',
                            },
                            { value: 'Demo', description: 'Demo' },
                            { value: 'Other', description: 'Other' },
                        ]"
                        item_value_idx="value"
                        item_desc_idx="description"
                    >
                    </kr-select>
                </div>
            </div>
        </template>
        <div class="col-xs-12 col-md-12 marginTop20">
            <kr-field
                display="NOTES <span class='important'>*</span>"
                :form="form"
                name="notes"
                aria-label="notes"
                :options="{
                    htmlEditor: true,
                    htmlEditorHeight: 250,
                    showError: false,
                }"
            ></kr-field>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'superusers'],
};
</script>
