/*Kelvin Roger Ang Yap 2017
	krayap@gmail.com
*/

Vue.component('KrPanel', require('./components/kr-panel.vue').default);
Vue.component('KrPieChart', require('./components/kr-pie-chart.vue').default);
Vue.component('KrScatterChart', require('./components/kr-scatter-chart.vue').default);
Vue.component('KrBarChart', require('./components/kr-bar-chart.vue').default);
Vue.component('KrBoxplotChart', require('./components/kr-boxplot-chart.vue').default);
Vue.component('KrMath', require('./components/kr-math.vue').default);
